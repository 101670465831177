import React, { useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import Validator, { ValidatorFormRules } from './Validator';
import { bindFormChange } from './Util';

const useScript = (url: string) => {
	useEffect(() => {
		const script = document.createElement('script');
		
		script.src = url;
		script.async = true;
		
		document.body.appendChild(script);
		
		return () => {
			document.body.removeChild(script);
		}
	}, [url]);
};

interface PayfrontTokenCard{
	card_name: string, 
	card_number: string, 
	expiration_month: number, 
	expiration_year: number, 
	card_cvv: string, 
	amount: number,
	postal_code: string,
	client_id?: number,
	public_key?: string,
}

interface PayfrontError{
	message_client: string
	message: string,
}

const usePayfront = (client_id?: number, public_key?: string)=>{
	var [initiated, setInitiated] = useState<boolean>(false);
	useEffect(() => {
		var script = document.createElement('script');
		script.src = 'https://api.payfront.mx/js/2.1.1/payfront.min.js';
		document.body.appendChild(script);
		return ()=>{
			document.body.removeChild(script);
		}
	}, [client_id, public_key]);

	var createToken = (data: PayfrontTokenCard) : Promise<{ error: boolean, message?: string, message_internal?: string, data?: { token: string, digits: string, bin: string } }>=>{
		if(!(window as any).Payfront) return Promise.resolve({ error: true, message: 'Hubo un error configuración del procesador de pagos. (LCL-UPF-1)' });

		var bin = data.card_number.toString().substring(0, 6);
		var digits = data.card_number.toString().substring(-4);

		return new Promise((resolve, reject)=>{
			(window as any).Payfront.tokenizeCard({
				client_id,
				public_key,
				name: data.card_name, 
				number: data.card_number, 
				exp: data.expiration_month+''+data.expiration_year, 
				cvv: data.card_cvv,
				amount: data.amount,
				postal_code: data.postal_code
			}, function(err: PayfrontError, token: string){
				return resolve({
					error: !!err,
					message_internal: err ? err.message : undefined,
					message: err ? err.message_client : undefined,
					data: !err ? { token, digits, bin } : undefined
				});
			})
		})
	}

	return {
		createToken,
	}
}

var TitleContext = createContext<{ setTitle: (title: string)=>void }>({ setTitle: (v: string)=>null });
var useTitle = () => useContext(TitleContext);

interface TitleProps extends PropsWithChildren{
	default?: string,
	suffix?: string,
}

var TitleProvider = (props: TitleProps) => {
	var [titleHistory, setTitleHistory] = useState<{ pathname: string, title: string }[]>([]);
	var location = useLocation();

	useEffect(()=>{
		var suffix = props.suffix || 'AREMA Ticket';
		var title = titleHistory.find(a=>a.pathname===location.pathname);
		if(!title || !title.title){
			document.title = suffix;
		}else{
			document.title = `${title.title} - ${suffix}`;
		}
	}, [location, titleHistory]);

	var changeTitle = (title: string)=>{
		if(!location.key) return;
		var hs = [...titleHistory];
		hs = hs.filter(a=>a.pathname!==location.pathname);
		hs.push({ pathname: location.pathname, title });
		setTitleHistory(hs);
	}

	return (
		<TitleContext.Provider value={{ setTitle: changeTitle }}>
			{props.children}
		</TitleContext.Provider>
	)
}

const useValidator = <T,>(initValues: T, rules?: ValidatorFormRules<T>)=>{
	var [data, setData] = useState<T>(initValues);
	var [prompts, setPrompts] = useState<string[]>(null);
	var [errors, setErrors] = useState<{
		[x in keyof T]: boolean
	}>({} as any);

	useEffect(()=>{
		if(data && typeof data==='object' && Object.keys(errors).length===0){
			var err : any = {};
			for(var i in data){
				err[i] = false;
			}
			setErrors(err);
			reset()
		}
	}, []);

	var reset = ()=>{
		if(initValues && typeof initValues==='object'){
			var dt : any = {};
			for(var v in initValues){
				dt[v] = initValues[v];
			}
			setData(dt);
		}
	}

	var validate = (data_override?: any, rules_override?: ValidatorFormRules<T>) : { valid: boolean, prompts: string[], errors: typeof errors }=>{
		if(!rules) return { valid: true, prompts: [], errors };
		// console.log(data);
		// console.log({ ...data, ...data_override }, { ...rules, ...rules_override });
		var vld = Validator({ ...data, ...data_override }, { ...rules, ...rules_override });

		var nw_errors : any = {};
		for(var i in data){
			nw_errors[i] = vld.errors[i];
		}
		setErrors(nw_errors);
		setPrompts(vld.prompts);
		return {
			valid: vld.valid,
			prompts,
			errors: nw_errors
		}
	}

	var onDataChange = (name: keyof T, semantic: boolean=false, target: boolean=false)=>{
		if(errors[name] && rules && rules[name]){
			var valid = Validator.validate(rules[name], data[name], data);
			if(valid){
				setErrors({
					...errors,
					[name]: !valid
				});
			}
		}

		return bindFormChange(data, setData)(name, semantic, target);
	}

	return {
		data,
		errors,
		onDataChange,
		prompts,
		reset,
		setPrompts,
		validate,
	}
}

export {
	usePayfront,
	useScript,
	useTitle,
	useValidator,
	TitleProvider
};