import React, { useEffect, useState } from 'react';
import { Button, Groupper, Header, Image, Table, Icon } from 'react-frontier';
import { useParams } from 'react-router-dom'
import { formatSeatNumber, isJWT } from '@arema/components/Util';
import { ErrorHeader } from '../components';
import { useTitle } from '@arema/components/Hooks';
import { OrderDownload } from '@arema/components/Classes';
import { CDN_URL, PAGE, PAGE_DOWNLOAD, PLACEHOLDER_IMG } from '../TicketsConfig';
import { Link } from 'react-router-dom'
import { InstructionsOxxo, InstructionsSPEI } from '@arema/components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import API from '../TicketsAPI';
import classNames from 'classnames';

import '../style/tickets_download.scss';

var TicketDownload = ()=>{
	var { t, i18n } = useTranslation();
	var { setTitle } = useTitle();
	var { hash } = useParams<{ hash: string }>();
	var [order, setOrder] = useState<OrderDownload>(null);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(()=>{
		if(!isJWT(hash)) return;
		if(!order){
			API.getTickets(hash).then(res=>{
				if(res.error) return setLoadError(res.message);
				setOrder(res.data);
				setTitle(`${t('common.tickets')} ${res.data.order_hash}`);
			}).catch(err=>{
				return setLoadError(t('common.error_code', { type: 'cargando los boletos', code: 'LCL-1' }))
			});
		}
	}, [hash]);

	var hasAppleWallet = ()=>{
		return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || navigator.userAgent.includes("Mac");
	}

	if(!isJWT(hash)) return <ErrorHeader />
	if(loadError) return <ErrorHeader text={t('common.error')} subtext={loadError} />
	if(!order) return <Header loading text={t('download.loading')} />
	var pending_payments = order.payments.filter(a=>!a.paid && [20, 40].indexOf(a.payment_method)!=-1);
	var DOWNLOAD_ALL = `${PAGE_DOWNLOAD}/tk/${order.download_token}`;
	
	return <div>
		{!!order.tickets && order.tickets.length>0 && (
			<Groupper title={t('common.tickets')} titleCentered width={500} className="tickets">
				{order.tickets.length>1 && <>
					<Button className='all' size='big' text={t('download.button_all')} color='orange' as={Link} to={DOWNLOAD_ALL} target={'_blank'} />
					<div className="divider dashed"></div>
				</>}
				{order.tickets.map((a,i)=><>
					<div className="ar ticket">
						<div className="info">
							<Image src={`${CDN_URL}/events/${a.event_id}/200.webp`} fallback={PLACEHOLDER_IMG} />
							<div className="data">
								<div className="meta">{t('common.ticket')} {a.ticket_hash}</div>
								<div className="event">{a.event_name}</div>
								<div className="details">
									<b>{a.section_name}</b> - {a.price_name}
								</div>
								{!!a.numbered && (
									<div className="details">
										<b>{a.seat_section || t('common.seat')}</b> {formatSeatNumber(a.seat_row, a.seat_number)}
									</div>
								)}
							</div>
						</div>
						<div className="buttons">
							{hasAppleWallet() && (
								<Link to={`${PAGE_DOWNLOAD}/tks/${a.download_token}?w=1`} target='_blank'>
									<img src={`${PAGE}/apple_wallet.${i18n.language}.svg`} alt="" />
								</Link>
							)}
							<Button text={t('common.download')} color='orange' as={Link} to={`${PAGE_DOWNLOAD}/tks/${a.download_token}`} target={'_blank'} />
						</div>
					</div>
					{i<(order.tickets.length-1) && (
						<div className='divider dashed'></div>
					)}
				</>)}
			</Groupper>
		)}
		{pending_payments.length>0 && (
			(order.tickets.length==0 || order.tickets_total>order.tickets.length) && (
				order.payments.map(a=>(
					<Groupper width={500} title={t('download.payment_instructions')} style={{ marginBottom: 15 }} key={`pay-${a.payment_id}`}>
						{a.payment_method==20 ? (
							<InstructionsOxxo amount={a.total} reference={a.reference} download_url={DOWNLOAD_ALL} />	
						) : a.payment_method==40 ? (
							<InstructionsSPEI amount={a.total} download_url={DOWNLOAD_ALL} transfer_data={a.transfer_data} />	
						) : (
							<Header text={t('common.error')} />
						)}
					</Groupper>
				))
			)
		)}
		{/* <Groupper width={500} title='Boletos' style={{ marginTop: 15 }}>
			<Header text='Orden no pagada' subtext='Esta orden no se ha pagado, favor de realizar el pago para poder descargar los boletos' />
		</Groupper> */}
		<Table
			details
			titleCentered
			striped
			title={t('download.order_details')}
			style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
			data={[
				[t('common.order'), order.order_hash],
				...(order.tickets.length>0 ? [
					[t('common.tickets'), order.tickets.length],
				] : []),
				[t('common.status'), <div className={classNames({
					green: order.paid,
					yellow: !order.paid
				})}>
					<Icon name={order.paid ? 'check-circle' : 'clock'} />
					{order.paid ? t('common.paid') : t('common.payment_pending')}
				</div>],
				[t('common.date_created'), moment.unix(order.date_created).format('DD/MMM/YY HH:mm')],
				[t('common.date_paid'), order.paid ? moment.unix(order.date_paid).format('DD/MMM/YY HH:mm') : <Icon name='minus' color='gray' />],
				null,
				...(order.first_name || order.last_name ? [
					[t('download.order.name'), `${(order.first_name || '').trim()} ${(order.last_name || '')}`]
				] : []),
				[t('download.order.email'), order.email],
			]}
		/>
	</div>
}

export default TicketDownload;