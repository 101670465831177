import resourcesToBackend from "i18next-resources-to-backend";

type Site = 'admin' | 'public' | 'reports' | 'checkout' | 'tickets';

const Locale = (site: Site)=>{
	return resourcesToBackend(async (language: string)=>{
		var common = (await import(`./${language}/common.${language}.json`)).default;
		var lang = (await import(`./${language}/${site}.${language}.json`)).default;

		if(lang.common){
			lang.common = {
				...lang.common,
				...common
			}
		}else{
			lang.common = common;
		}
		return lang;
	})
}

export default Locale;