import { SemanticICONS } from "semantic-ui-react";
import { clabe } from 'clabe-validator';
import { ValidatorFormRules } from './Validator';
import { TicketFormPayload, TicketQuestion, QuestionType, Location, DepositType } from "./Classes";
import i18next from "i18next";

const MEXICO_STATES = [
	'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 
	'Coahuila', 'Colima', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Durango', 'Guanajuato', 
	'Guerrero', 'Hidalgo', 'Jalisco', 'México', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 
	'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 
	'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'
];

export type Icons = SemanticICONS 
	| 'seat'
	| 'cash register'
	| 'user tag'
	| 'user tie'
	| 'user cog'
	| 'theater masks'
	| 'face frown open'
	| 'pen'
	| 'hand holding usd'
	| 'pdf file'
	| 'face frown'
	| 'face grin wide'
	;

const bindClose = <T>(setVal: (val: T)=>void, val: T=null)=>{
	return ()=>{
		setVal(val);
	}
}

const bindChange = (setVal: (val: any, ...args: any)=>void, semantic: boolean=false)=>{
	return (e: { target: { value: string } } | any, data?: { value?: any, checked?: any })=>{
		if(semantic){
			return setVal(data.checked || data.value || null);
		}else{
			return setVal(e.target.value);
		}
	}
}

const bindSemantic = (setVal: (val: any)=>void)=>{
	return (e: any, data: { value?: any, checked?: any })=>{
		return setVal(data.checked || data.value);
	}
}

const bindToggle = (val: any, setVal: any)=>{
	return ()=>{
		setVal(!val);
	}
}

const bindFormChange = <T>(val: T, setVal: (val: any, ...args: any)=>void, setDirty?: (val: boolean)=>void)=>{
	return (name: keyof T, semantic: boolean=false, target: boolean=false)=>{
		return (e: { target: { value: string } } | any, data?: { value?: any, checked: boolean } | any)=>{
			if(semantic){
				setVal({ ...val, [name]: typeof data.checked!=='undefined' ? data.checked : data.value });
			}else if(target){
				setVal({ ...val, [name]: e.target.value })
			}else{
				setVal({ ...val, [name]: e });
			}
			if(setDirty) setDirty(true);
		}
	}
}

function addCommas(num: number, fixed: boolean=true, hideZero: boolean=false){
	if (!num && num !== 0) return null;
	if(typeof num==='string'){
		num = parseFloat(num as string);
	}
	var c = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	var dec = c.substring(c.length-2, c.length);
	return fixed ? (hideZero && dec == '00' ? c.slice(0, -3) : c) : c.slice(0, -3);
}

function dashify(what: any, num: number=4, delimiter: string='-'){
	what = what.toString();
	var ret = '';
	for(var i=0; i<what.length; i++){
		if(i%num==0 && i!=0)ret+=delimiter;
		ret += what[i];
	}
	return ret;
}

function unix(){
	return Math.floor(new Date().getTime()/1000);
}

function isActive(date_start: number, date_end: number){
	var now = unix();
	return now>=date_start && now<=date_end;
}

function formatSeatNumber(seat_row: string, seat_number: string){
	if(!seat_row || !seat_number) return seat_number || seat_row;
	var row_number = Number.isNaN(parseInt(seat_row[seat_row.length-1]));
	var col_number = Number.isNaN(parseInt(seat_number[0]));
	
	var hyphenated = (row_number && col_number) || (!row_number && !col_number);
	return `${seat_row || ''}${hyphenated ? '-' : ''}${seat_number || ''}`;
}

function seatStatusLocale(seat_status: number){
	switch(seat_status){
		case 1: return 'Libre';
		case 20: return 'Bloqueada';
		case 21: return 'Candado';
		case 40: return 'Escondida';
	}
}

function uniqueArray<T>(array: T[]){
	return Array.from<T>(new Set(array));
}

function groupBy<T>(objs: T[], key: keyof T, inherit: (keyof T)[]=null){
	var k = uniqueArray(objs.map(a=>a[key]));
	var result = [];
	for(let i of k){
		var r : any = {};
		r[key] = i;
		r['values'] = objs.filter(a=>a[key]==i);
		result.push(r);
	}
	if(inherit && Array.isArray(inherit)){
		for(let i of result){
			for(let j of inherit){
				i[j] = i['values'][0][j];
			}
		}
	}
	return result as { [x: string]: any, values: T[] }[];
}

function getTicketFormPayload(form: TicketQuestion[]){
	var data : TicketFormPayload[] = form.map(a=>{
		var data : TicketFormPayload = { question_id: a.question_id, required: a.required }
		if(a.question_type==QuestionType.FILE_IMAGE || a.question_type==QuestionType.FILE_OTHER){
			if(a.files){
				data.files = a.files;
			}else data.files = null;
		}else if(a.question_type==QuestionType.MULTIPLE || a.question_type==QuestionType.SELECTION){
			data.selected = a.selected && a.selected.length>0 ? a.selected : (a.value_id ? [a.value_id] : []);
		}else{
			data.answer = a.answer?.toString();
		}
		data.empty = !((data.answer && data.answer.length>0) || (data.selected && data.selected.length>0) || (data.files && data.files.length>0))
		return data;
	});

	return data.filter(a=>(a.required || !a.empty));
}

function ticketPayloadFormData(tickets: { ticket_id: any, form: TicketFormPayload[] }[]){
	var formdata = new FormData();
	for(var t of tickets){
		for(var i=0; i<t.form.length; i++){
			var a = t.form[i];
			if((a.files && a.files.length>0) || (a.selected && a.selected.length>0) || a.answer){
				formdata.append(`answers[${t.ticket_id}][${i}][question_id]`, a.question_id.toString());
			}else continue;
			if(a.files){
				a.files.forEach((v, ix)=>{
					formdata.append(`answers[${t.ticket_id}][${i}][files][${ix}]`, v);
				})
			}else if(a.selected && a.selected.length>0){
				a.selected.forEach((v, ix)=>{
					formdata.append(`answers[${t.ticket_id}][${i}][selected][${ix}]`, v.toString());
				});
			}else if(a.answer){
				formdata.append(`answers[${t.ticket_id}][${i}][answer]`, a.answer);
			}
		}
	}
	return formdata;
}

function formatExpiration(val: string){
	val = val.replace(/\//g, '');
	if(val.length>=3){
		return val.replace(/\//g, '').substring(0, 2) + '/' + val.substring(2);
	}
	return val.replace(/\//g, '');
}

function formatCreditCard(val: string){
	val = val.replace(/\ /g, '');
	var final_val = '';
	for(var i=0; i<val.length; i++){
		final_val += val[i];
		if(((i+1)%4)==0 && i<(val.length-1)){
			final_val += ' '
		}
	}
	return final_val;
}

function validatePassword(val: string){
	return (/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*\.\,\+\*])[a-zA-Z0-9!@#$%^&*\.\,\+\*]{8,32}$/).test(val);
}

function partition<T>(a: T[], n: number) : T[][]{
	var array = [...a];
	return array.length ? [array.splice(0, n)].concat(partition(array, n)) : [];
}

function cropString(str: string, len: number, elip: boolean=false){
	if(!str) return '';
	var elip = elip && str.length>len;
	return str.trim().substring(0, len-(elip ? 3 : 0)).trim() + (elip ? '...' : '');
}

function randomRange(min: number, max: number){
	return Math.floor(Math.random() * (max-min+1)+min);
}

function formatPlacesComponents(result: any){
	var place : any = {
		name: result.name,
		place_id: result.place_id,
		phone: null,
		street: null,
		exterior_number: null,
		interior_number: null,
		neighborhood: null,
		city: null,
		state: null,
		zipcode: null,
		country: null,
		lattitude: null,
		longitude: null,
	}
	var place_identifiers: any = {
		street: {
			type: ['street_address', 'route'],
		},
		exterior_number: {
			type: 'street_number'
		},
		interior_number: {
			type: ['room', 'subpremise']
		},
		neighborhood: {
			type: ['neighborhood', 'sublocality']
		},
		city: {
			type: 'locality'
		},
		zipcode: {
			type: 'postal_code'
		},
		state: {
			long_name: true,
			type: 'administrative_area_level_1'
		},
		country: {
			type: 'country'
		}
	}

	var coords = result.geometry.location.toJSON();
	place.longitude = coords.lng;
	place.lattitude = coords.lat;

	for(var i of result.address_components){
		for(var k of Object.keys(place_identifiers)){
			var types = place_identifiers[k].type;
			if(!Array.isArray(types)) types = [types];
			for(var t of types){
				if(i.types.indexOf(t)!=-1){
					if(k=='address_2' && place['street']==i.long_name) continue;
					place[k] = place_identifiers[k].long_name===false ? i.short_name : i.long_name
					break;
				}
			}
		}
	}
	return place as Location;
}

function checkLocation(location: any){
	var required = ['name', 'street', 'exterior_number', 'neighborhood', 'city', 'state', 'zipcode', 'country']
	var cols = ['name', 'phone', 'street', 'exterior_number', 'interior_number', 'neighborhood', 'city', 'state', 'zipcode', 'country', 'lattitude', 'longitude', 'place_id', 'comments']
	for(var i of required){
		if(!location || !location[i] || location[i].length==0 || typeof location[i]==='undefined'){
			return false;
		}
	}
	for(var i in location){
		if(cols.indexOf(i)==-1) return false;
	}
	return true;
}

function numberToLetter(num: number, doubleletter: boolean = true) : string{
	let s: string = '', t: number;
	while (num > 0) {
		t = (num - 1) % 26;
		s = String.fromCharCode(65 + t) + s; 
		if (doubleletter && (num > 26)) {
			const numtrn = Math.floor(Math.floor(num/26)/26);
			const posicion = encontrarPosicionTriangular(numtrn) + (t+1 === 26 ? 0 : 1);
			for (let index = 0; index < posicion; index++) {	
				s = s+numberToLetter(t+1);
			}
			num = 0;
		} else {
			num = (num - t)/26 | 0;
		}
	}
	return s || '0';
}

function encontrarPosicionTriangular(numeroTriangular: number) {
	const posicion = Math.sqrt(2 * numeroTriangular + 0.25) - 0.5;
	return Math.ceil(posicion);
}


function letterToNumber(val: string){
	if(!val) return 0;
	if(!(/[a-z]+/i).test(val)) return -1;
	if(val.length==0) return 0;
	var letters = val.toUpperCase().split('').reverse();
	var num = 0;
	for(var i=0; i<letters.length; i++){
		var lnum = letters[i].charCodeAt(0)-64;
		if(i>0){
			num += (i*26)*lnum;
		}else{
			num += lnum;
		}
	}
	return num;
}

function isJWT(token: string){
	return (/(^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$)/gi).test(token);
}

function getDateStatusLocale(status_id: number){
	// TODO: Convert to locale
	switch(status_id){
		case 3: return 'Próximamente';
		case 4: return 'Solo en taquilla';
		case 5: return 'Agotado';
		case 6: return 'Cerrado';
		case 7: return 'Cancelado';
		default: return 'No disponible';
	}
}

function validateClabeParticipant(clabe: string){
	return ['001', '002', '006', '009',
	'012', '014', '019', '021',
	'030', '036', '042', '044',
	'058', '059', '060', '062',
	'072', '106', '108', '110',
	'112', '113', '126', '127',
	'128', '129', '130', '132',
	'133', '135', '136', '137',
	'138', '140', '141', '143',
	'145', '147', '148', '150',
	'151', '152', '154', '155',
	'156', '157', '158', '159',
	'160', '166', '166', '168',
	'600', '601', '602', '605',
	'608', '613', '616', '617',
	'620', '630', '631', '634',
	'638', '646', '648', '648',
	'652', '653', '656', '659',
	'670', '677', '680', '683',
	'684', '684', '685', '686',
	'688', '689', '703', '706',
	'710', '901', '902', '903'].indexOf(clabe.toString().substring(0, 3))!=-1;
}

function getBankParticipants(){
	return [
		{ participant: 40138, bank_name: "ABC CAPITAL" },
		{ participant: 40133, bank_name: "ACTINVER" },
		{ participant: 40062, bank_name: "AFIRME" },
		{ participant: 90706, bank_name: "ARCUS" },
		{ participant: 90659, bank_name: "ASP INTEGRA OPC" },
		{ participant: 40128, bank_name: "AUTOFIN" },
		{ participant: 40127, bank_name: "AZTECA" },
		{ participant: 37166, bank_name: "BaBien" },
		{ participant: 40030, bank_name: "BAJIO" },
		{ participant: 40002, bank_name: "BANAMEX" },
		{ participant: 40154, bank_name: "BANCO FINTERRA" },
		{ participant: 40160, bank_name: "BANCO S3" },
		{ participant: 37006, bank_name: "BANCOMEXT" },
		{ participant: 40137, bank_name: "BANCOPPEL" },
		{ participant: 40152, bank_name: "BANCREA" },
		{ participant: 37019, bank_name: "BANJERCITO" },
		{ participant: 40106, bank_name: "BANK OF AMERICA" },
		{ participant: 40159, bank_name: "BANK OF CHINA" },
		{ participant: 40147, bank_name: "BANKAOOL" },
		{ participant: 37009, bank_name: "BANOBRAS" },
		{ participant: 40072, bank_name: "BANORTE/IXE" },
		{ participant: 40058, bank_name: "BANREGIO" },
		{ participant: 37166, bank_name: "BANSEFI" },
		{ participant: 40060, bank_name: "BANSI" },
		{ participant: 2001, bank_name: "BANXICO" },
		{ participant: 40129, bank_name: "BARCLAYS" },
		{ participant: 40145, bank_name: "BBASE" },
		{ participant: 40012, bank_name: "BBVA MEXICO" },
		{ participant: 40112, bank_name: "BMONEX" },
		{ participant: 90677, bank_name: "CAJA POP MEXICA" },
		{ participant: 90683, bank_name: "CAJA TELEFONIST" },
		{ participant: 90630, bank_name: "CB INTERCAM" },
		{ participant: 90631, bank_name: "CI BOLSA" },
		{ participant: 40143, bank_name: "CIBANCO" },
		{ participant: 90901, bank_name: "CLS" },
		{ participant: 90903, bank_name: "CoDi Valida" },
		{ participant: 40130, bank_name: "COMPARTAMOS" },
		{ participant: 40140, bank_name: "CONSUBANCO" },
		{ participant: 90652, bank_name: "CREDICAPITAL" },
		{ participant: 90688, bank_name: "CREDICLUB" },
		{ participant: 40126, bank_name: "CREDIT SUISSE" },
		{ participant: 90680, bank_name: "CRISTOBAL COLON" },
		{ participant: 40151, bank_name: "DONDE" },
		{ participant: 90648, bank_name: "EVERCORE" },
		{ participant: 90616, bank_name: "FINAMEX" },
		{ participant: 90634, bank_name: "FINCOMUN" },
		{ participant: 90689, bank_name: "FOMPED" },
		{ participant: 90685, bank_name: "FONDO (FIRA)" },
		{ participant: 90601, bank_name: "GBM" },
		{ participant: 37168, bank_name: "HIPOTECARIA FED" },
		{ participant: 40021, bank_name: "HSBC" },
		{ participant: 40155, bank_name: "ICBC" },
		{ participant: 40036, bank_name: "INBURSA" },
		{ participant: 90902, bank_name: "INDEVAL" },
		{ participant: 40150, bank_name: "INMOBILIARIO" },
		{ participant: 40136, bank_name: "INTERCAM BANCO" },
		{ participant: 90686, bank_name: "INVERCAP" },
		{ participant: 40059, bank_name: "INVEX" },
		{ participant: 40110, bank_name: "JP MORGAN" },
		{ participant: 90653, bank_name: "KUSPIT*" },
		{ participant: 90670, bank_name: "LIBERTAD" },
		{ participant: 90602, bank_name: "MASARI" },
		{ participant: 40042, bank_name: "MIFEL" },
		{ participant: 40158, bank_name: "MIZUHO BANK" },
		{ participant: 90600, bank_name: "MONEXCB" },
		{ participant: 40108, bank_name: "MUFG" },
		{ participant: 40132, bank_name: "MULTIVA BANCO" },
		{ participant: 90613, bank_name: "MULTIVA CBOLSA" },
		{ participant: 37135, bank_name: "NAFIN" },
		{ participant: 90638, bank_name: "NU MEXICO" },
		{ participant: 90710, bank_name: "NVIO" },
		{ participant: 90684, bank_name: "OPM" },
		{ participant: 40148, bank_name: "PAGATODO" },
		{ participant: 90620, bank_name: "PROFUTURO" },
		{ participant: 40156, bank_name: "SABADELL" },
		{ participant: 40014, bank_name: "SANTANDER" },
		{ participant: 40044, bank_name: "SCOTIABANK" },
		{ participant: 40157, bank_name: "SHINHAN" },
		{ participant: 90646, bank_name: "STP" },
		{ participant: 90648, bank_name: "TACTIV CB" },
		{ participant: 90703, bank_name: "TESORED" },
		{ participant: 90684, bank_name: "TRANSFER" },
		{ participant: 90656, bank_name: "UNAGRA" },
		{ participant: 90617, bank_name: "VALMEX" },
		{ participant: 90605, bank_name: "VALUE" },
		{ participant: 40113, bank_name: "VE POR MAS" },
		{ participant: 90608, bank_name: "VECTOR" },
		{ participant: 40141, bank_name: "VOLKSWAGEN" },
	]
}

function getParticipant(participant: number){
	var banks = getBankParticipants();
	return banks.find(a=>a.participant===participant);
}

function isCardValid(number: string){
	number = number.replace(/\D/g,'');

	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null) return true;
	
	// Mastercard 
	// Updated for Mastercard 2017 BINs expansion
	if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) return true;
	
	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null) return true;
	
	// Discover
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null) return true;
	
	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null) return true;
	
	return false;
}

function formatCommission(percent: number, amount: number){
	if(percent==0 && amount==0) return addCommas(0);
	return `${percent>0 ? (addCommas(percent, true, true) + '%') : ''}${(!!percent && percent>0 && !!amount && amount>0) ? ' + ' : ''}${amount>0 ? ('$'+addCommas(amount)) : ''}`;
}

function getIndexes(arr: boolean[]){
	var ix : number[] = [];
	for(var i=0; i<arr.length; i++){
		if(!!arr[i]) ix.push(i);
	}
	return ix;
}

function getRefundValidator(type: DepositType) : ValidatorFormRules{
	return {
		type: [{ rule: 'empty', prompt: 'Favor de seleccionar el tipo de transferencia.' }],
		account_owner: [{ rule: 'minLength', params: [3], prompt: `Favor de ingresar el propietario de ${type===DepositType.CARD ? 'la tarjeta' : 'la cuenta'}` }],
		...(type===DepositType.CARD ? {
			card_number: [{ rule: 'creditcard' }],
			bank: [{ rule: 'empty', prompt: 'Favor de seleccionar el banco de la tarjeta' }],
		} : type===DepositType.SPEI ? {
			clabe: [{ 
				rule: (v: string)=>{
					var val = clabe.validate(v);
					console.log(val);
					return val && val.ok;
				}, 
				prompt: 'La CLABE no es válida.' 
			}, {
				skipEmpty: true,
				rule: validateClabeParticipant,
				prompt: 'La CLABE no pertenece a un banco válido.'
			}]
		} : {})
	}
}

function relativeTime(end: number, start?: number){
	if(!start) start = Math.floor(new Date().getTime()/1000);

	var start_date = new Date(start*1000), end_date = new Date(end*1000);
	var hours = Math.floor((end-start)/3600);

	if(start_date.getTime()>end_date.getTime()){ // Its a past date
		var temp = start_date;
		start_date = end_date;
		end_date = temp;
	}

	var months = ((end_date.getFullYear()*12)+end_date.getMonth())-((start_date.getFullYear()*12)+start_date.getMonth());
	
	var diff : string[] = [];
	if(months>0){

		// var total_days = Math.floor(hours/24);
		var month_days = 0;

		// Remaining days of start month. Ex. (Max days of month)-(today's date)
		var start_days = new Date(start_date.getFullYear(), start_date.getMonth()+1, 0).getDate()
		var start_remain = start_days-start_date.getDate();

		// Remaining days of end month. Ex. Jan 15 = 15 days
		var end_remain = end_date.getDate();

		// Check if remaining days > start month
		if((start_remain+end_remain)>start_days){
			month_days += (start_remain+end_remain)-start_days;
			months += 1;
		}else{
			month_days += start_remain + end_remain + 1;
		}

		if((months-1)>0){
			diff.push(i18next.t('common.time.months', { count: months-1 }));
		}
		diff.push(i18next.t('common.time.days', { count: month_days }));
	}else{
		diff.push(i18next.t('common.time.days', { count: Math.abs(Math.floor(hours/24)) }));
	}
	return i18next.t(`common.time.${(end-start)<0 ? 'ago' : 'in'}`, { value: diff.join(', ') })
}

export {
	addCommas,
	bindChange,
	bindClose,
	bindFormChange,
	bindSemantic,
	bindToggle,
	checkLocation,
	cropString,
	dashify,
	formatCommission,
	formatCreditCard,
	formatExpiration,
	formatPlacesComponents,
	formatSeatNumber,
	getBankParticipants,
	getDateStatusLocale,
	getIndexes,
	getParticipant,
	getRefundValidator,
	getTicketFormPayload,
	groupBy,
	isActive,
	isCardValid,
	isJWT,
	letterToNumber,
	numberToLetter,
	partition,
	randomRange,
	relativeTime,
	seatStatusLocale,
	ticketPayloadFormData,
	uniqueArray,
	unix,
	validateClabeParticipant,
	validatePassword,
	MEXICO_STATES,
}