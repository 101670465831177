import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet } from 'react-router-dom';
import { TitleProvider } from '@arema/components/Hooks'
import { Header } from 'react-frontier';
import { CDN_ROOT, PAGE_PUBLIC } from './TicketsConfig';
import { initReactI18next, useTranslation } from 'react-i18next';
import { addCommas } from '@arema/components/Util';
import Locale from '@arema/components/src/locale';
import i18n from 'i18next';

import {
	TicketDownload,
} from './screens'

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';
import './style/tickets_site.scss';

i18n.use(initReactI18next).use(Locale('tickets')).init({
	fallbackLng: 'es',
	interpolation: {
		escapeValue: false,
	}
});

i18n.services.formatter.addCached('commas', (lng, options: { fixed: boolean, hideZero: boolean })=>{
	return val=>{
		return addCommas(val, options.fixed===true, options.hideZero===true);
	}
})


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = ()=>{
	var { t } = useTranslation();
	const error = (useRouteError() as any);
	return <Header
		text={(error?.status || 404)}
		subtext={(!error || error.status===404) ? t('common.notfound') : t('common.unexpected_code', { code: `(PBL-LCL-${(error?.status || 404)})` })}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 10 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	return <TitleProvider>
		<div style={{ paddingBottom: 50 }}>
			<div className="ar header">
				<a href={PAGE_PUBLIC}>
					<img src={`${CDN_ROOT}/assets/logo/LogoWH_Large.png`} alt="AREMA" />
				</a>
			</div>
			<div style={{ paddingTop: 10 }}>
				{props.outlet}
			</div>
		</div>
	</TitleProvider>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/', element: <ErrorElement /> },
		{ path: '/:hash', element: <TicketDownload /> },
	]
}]);

var Root = ()=>{
	return <RouterProvider router={Router} />
}

root.render(<Root />);