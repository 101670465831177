import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Icons } from '@arema/components/Util';
import { Link, useLocation } from 'react-router-dom';
import { useTitle } from '@arema/components/Hooks';
import classNames from 'classnames';
import { Icon, IconName } from 'react-frontier';

interface NotFoundProps{
	code?: string,
	subtext?: string,
	style?: React.CSSProperties
}

var NotFound = (props: NotFoundProps)=>{
	return <div className="fr centered header" style={{ fontSize: 100, ...props.style }}>
		{props.code || '404'}
		<div className="sub header" style={{ fontSize: 20 }}>
			{props.subtext || 'Página no encontrada'}
		</div>
	</div>
}

interface SidebarItemProps extends PropsWithChildren{
	iconName?: IconName,
	text?: string,
	className?: string
	active?: boolean,
	style?: React.CSSProperties,
	to?: string,
	target?: React.HTMLAttributeAnchorTarget,
	selectable?: boolean,
	subitem?: boolean,
}

var SidebarItem = (props: SidebarItemProps)=>{
	var className = classNames('item', { active: props.active, noselect: props.selectable===false }, props.className)
	const Component = props.to ? (
		<Link className={className} style={props.style} to={props.to} target={props.target}>
			{!!props.iconName && <Icon name={props.iconName} />}
			{props.text}
			{props.children}
		</Link>
	) : (
		<div className={className} style={props.style}>
			{!!props.iconName && <Icon name={props.iconName} />}
			{props.text}
			{props.children}
		</div>
	)
	if(props.subitem){
		return <div className="subitem">
			{Component}
		</div>
	}else{
		return Component;
	}
}

interface SidebarDividerProps{
	className?: string,
	style?: React.CSSProperties,
}
var SidebarDivider = (props: SidebarDividerProps)=>{
	return <div className={classNames('divider', props.className)} style={props.style}></div>
}

interface SidebarRoute{
	url: string,
	title?: string,
	render?: JSX.Element
	component?: (...params: any)=>JSX.Element,
}

type SidebarSubComponents = {
	Item: typeof SidebarItem,
	Divider: typeof SidebarDivider
}

interface SidebarProps extends PropsWithChildren{
	className?: string,
	style?: React.CSSProperties,
	breakpoint?: number,
	header?: string,
	routes: SidebarRoute[],
	routeParams?: any,
	getTitle?: (r: SidebarRoute, ix: number)=>string,
	contentHeader?: JSX.Element,
	sidebarHeader?: JSX.Element,
	missingComponent?: JSX.Element,
}

const SidebarContainer : React.FC<SidebarProps> & SidebarSubComponents = (props: SidebarProps)=>{
	const SIDEBAR_BREAKPOINT = props.breakpoint || 675;
	var location = useLocation();
	var [mobile, setMobile] = useState<boolean>(false);
	var [sectionIndex, setSectionIndex] = useState<number>(null);
	var [drawer, setDrawer] = useState<boolean>(false);
	var { setTitle } = useTitle();

	useEffect(()=>{
		var onResize = ()=>{
			if(!mobile && window.innerWidth<=SIDEBAR_BREAKPOINT){
				setMobile(true);
			}else if(mobile && window.innerWidth>SIDEBAR_BREAKPOINT){
				setMobile(false);
			}
		}
		window.addEventListener('resize', onResize);
		setMobile(window.innerWidth<=SIDEBAR_BREAKPOINT)
		
		var new_sect = getSectionFromUrl(location.pathname);
		var found_index = null;
		for(var i=0; i<props.routes.length; i++){
			var sect = getSectionFromUrl(props.routes[i].url)
			if(sect===new_sect){
				found_index = i;
				break;
			}
			if(!sect) continue;
			var sect_reg = new RegExp(sect.replace(/\:([a-z0-9_]+)/gi, (m, p)=>`([^\/]+)`) + '$', 'gi');
			if(sect_reg.test(new_sect)){
				found_index = i;
				break;
			}
		}
		
		if(sectionIndex!==found_index){
			setDrawer(false);
			setSectionIndex(found_index);
			if(found_index!==null && found_index>-1){
				if(props.getTitle){
					var title = props.getTitle(props.routes[found_index], found_index);
					if(title){
						setTitle(title);
					}
				}else if(props.routes[found_index].title){
					setTitle(props.routes[found_index].title);
				}
			}
		}
		return ()=>{
			window.removeEventListener('resize', onResize);
		}
	}, [mobile, location.pathname]);

	var getSectionFromUrl = (url: string)=>{
		return url;
		// var reg = new RegExp(props.locationRegexp);
		// var reg_res = reg.exec(url);
		// return reg_res && reg_res.groups.section && reg_res.groups.section.length>0 ? reg_res.groups.section : null;
	}

	var getParamsFromUrl = (template: string, url: string)=>{
		var reg = template.replace(/\:([a-z0-9_]+)/g, (m, p)=>`(?<${p}>[a-z0-9_]+)`);
		var reg_res = new RegExp(reg+'$', 'gi').exec(url);
		return reg_res?.groups || {}
	}

	var isItemActive = (url: string)=>{
		var current_section = getSectionFromUrl(location.pathname);
		var check_section = getSectionFromUrl(url);
		
		// console.log(current_section, check_section, current_section===check_section)
		return current_section===check_section;
	}

	var toggleDrawer = ()=>{
		setDrawer(!drawer);
	}

	var Content: JSX.Element = null, content_params = {};
	if(sectionIndex!==null){
		if(props.routes[sectionIndex].render){
			Content = props.routes[sectionIndex].render;
		}else if(props.routes[sectionIndex].component){
			const ContentFn = props.routes[sectionIndex].component;
			content_params = getParamsFromUrl(props.routes[sectionIndex].url, location.pathname);
			Content = <ContentFn params={content_params} {...props.routeParams} />
		}
	}

	if(!Content && props.missingComponent){
		Content = props.missingComponent;
	}

	var childs = (Array.isArray(props.children) ? props.children : [props.children]).map((a,i)=>{
		if(React.isValidElement(a)){
			return React.cloneElement(a, {
				key: `SBITM-${i}`,
				active: (a.props as any).to ? isItemActive((a.props as any).to) : false,
				...(a.props as any),
			})
		}
	});
	
	return <div className={classNames('fr sidebar', {
		mobile,
		'drawer-active': drawer,
	}, props.className)} style={props.style}>
		{!mobile ? (
			<div className="sidebar">
				{!!props.header && (
					<div className="header item">{props.header}</div>
				)}
				{props.sidebarHeader}
				{childs}
			</div>
		) : (
			<div className={classNames('drawer', { active: drawer })} style={{ top: 40 }}>
				<div className="header" onClick={toggleDrawer}>
					{props.sidebarHeader ? (
						props.sidebarHeader
					) : <>
						<div className="text">{props.header}</div>
					</>}
					<i className="caret down icon"></i>
				</div>
				{drawer && (
					childs
				)}
			</div>
		)}
		<div className="contents">
			{sectionIndex===null ? (
				props.missingComponent || null
			) : sectionIndex===null || Content===null ? (
				<NotFound />
			) : <>
				{props.contentHeader}
				{Content}
			</>}
		</div>
		{drawer && (
			<div className="overlay" onClick={toggleDrawer}></div>
		)}
	</div>

}

SidebarContainer.Item = SidebarItem;
SidebarContainer.Divider = SidebarDivider;

export default SidebarContainer;